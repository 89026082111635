<template>
	<div v-if="show">
		<van-tabs scrollspy sticky color="#2594EF">
			<van-tab title="基本信息">
				<div class="content-box">
					<van-row type="flex" justify="space-between" align="center">
						<van-col class="content-title">基本信息</van-col>
						<van-col
							>保单号：<span class="color-primary" @click="download(info.insBillInfoVo.urlPath)">{{ info.insBillInfoVo.policyCode }}</span></van-col
						>
					</van-row>
					<div class="mt-20">
						<div class="info-title">投保人：</div>
						<div class="assured-info">
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>{{ info.insMasterInsureInfoVo.insType === 1 ? '姓名' : '名称' }}</van-col>
								<van-col>{{ info.insMasterInsureInfoVo.insName }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>证件类型</van-col>
								<van-col>{{ getType(info.insMasterInsureInfoVo.insCredentialsType) }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>出生日期</van-col>
								<van-col>{{ info.insMasterInsureInfoVo.insBirthday }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center">
								<van-col>邮箱</van-col>
								<van-col>{{ info.insMasterInsureInfoVo.insEmail ? info.insMasterInsureInfoVo.insEmail : '--' }}</van-col>
							</van-row>
						</div>
					</div>
					<div class="mt-20">
						<div class="info-title">被保人：</div>
						<div class="assured-info">
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>姓名</van-col>
								<van-col>{{ info.insAssuredVo.name }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>证件类型</van-col>
								<van-col>{{ getType(info.insAssuredVo.certificateType) }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center" class="mb-10">
								<van-col>证件号码</van-col>
								<van-col>{{ info.insAssuredVo.certificateContent }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center">
								<van-col>出生日期</van-col>
								<van-col>{{ info.insAssuredVo.birthday }}</van-col>
							</van-row>
							<van-row type="flex" justify="space-between" align="center">
								<van-col>手机号</van-col>
								<van-col>{{ info.insAssuredVo.mobile }}</van-col>
							</van-row>
						</div>
					</div>
					<van-row v-if="info.insBillInfoVo.enableDate" type="flex" justify="space-between" align="center" class="mt-20">
						<van-col>保障期限</van-col>
						<van-col>{{ $base.getDateTime(info.insBillInfoVo.enableDate) }} 至 {{ $base.getDateTime(info.insBillInfoVo.disEnableDate) }}</van-col>
					</van-row>
					<van-row type="flex" justify="space-between" align="center" class="mt-20">
						<van-col>保费</van-col>
						<van-col>{{ info.insBillInfoVo.premium }}元</van-col>
					</van-row>
				</div>
			</van-tab>
			<van-tab title="保障信息">
				<div class="content-box">
					<van-row type="flex" justify="space-between" align="center">
						<van-col class="content-title">保障信息</van-col>
						<van-col>单位（人民币 元）</van-col>
					</van-row>
					<van-row type="flex" justify="space-between" align="center" class="mt-20" v-for="item in info.insMasterContentInfoVoList" :key="item.contentId">
						<van-col>{{ item.contentName }}</van-col>
						<van-col>{{ item.price }}</van-col>
					</van-row>
					<div class="line"></div>
					<div class="rule-text">本页面保额为产品最高赔付限额，具体赔付规则详见电子保单及保险条款及特别约定</div>
				</div>
			</van-tab>
			<van-tab title="协议条款">
				<div class="content-box">
					<div class="content-title">条款协议</div>
					<van-row type="flex" justify="space-between" align="center" class="agreement-row" v-for="(item, index) in info.insProductBookVoList" :key="index" @click="download(item.docLink)">
						<van-col class="text-1">{{ item.docName }}</van-col>
						<van-col class="arrow_right"></van-col>
					</van-row>
				</div>
			</van-tab>
		</van-tabs>
		<fileDownload :show.sync="downloadShow" :url="fileUrl" @update:show="val => (downloadShow = val)" />
	</div>
</template>

<script>
import { Tabs, Tab, Row, Col } from 'vant';
import { http_getPolicyDetail } from '@/request/common';
import fileDownload from '@/components/fileDownload.vue';

export default {
	components: {
		[Tabs.name]: Tabs,
		[Tab.name]: Tab,
		[Row.name]: Row,
		[Col.name]: Col,
		fileDownload,
	},
	data() {
		return {
			info: {},
			show: false,
			downloadShow: false,
			fileUrl: '',
		};
	},
	mounted() {
		document.title = '保单详情';
		this.getData();
	},
	methods: {
		async getData() {
			const info = this.$route.query;
			const data = await http_getPolicyDetail(info);
			this.info = data;
			this.show = true;
		},
		download(url) {
			// window.open(url, '_blank');
			this.downloadShow = true;
			this.fileUrl = url;
		},
		getType(cardType) {
			switch (cardType) {
				case '01':
					return '身份证';
				case '02':
					console.log(99);
					return '护照';
				case '03':
					return '军人证';
				case '05':
					return '港澳回乡证';
				default:
					return '其他';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.content-box {
	font-size: 0.24rem;
	width: 94%;
	margin: 0.2rem auto 0;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 0.2rem;
	box-sizing: border-box;

	.content-title {
		font-size: 0.3rem;
		font-weight: 600;
	}
	.mt-20 {
		margin-top: 0.2rem;
	}
	.info-title {
		font-size: 0.24rem;
		font-weight: 600;
	}
	.assured-info {
		margin-top: 0.1rem;
		background-color: rgba(18, 18, 18, 0.04);
		padding: 0.1rem;
		border: solid 1px rgba(18, 18, 18, 0.06);
		border-radius: 8px;
		.mb-10 {
			margin-bottom: 0.1rem;
		}
	}
	.line {
		width: 100%;
		height: 1px;
		border-bottom: dashed 1px #ccc;
	}
	.rule-text {
		color: $font_color_info;
		margin-top: 0.1rem;
	}
	.agreement-row {
		padding: 0.2rem 0;
		border-bottom: solid 1px rgba(0, 0, 0, 0.1);
		&:last-child {
			border: 0;
		}
	}
	.text-1 {
		width: 86%;
	}
}
.color-primary {
	color: $b_color_main;
}
</style>
